<template>
  <div>
    <a-card>
      <div slot="title" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <strong class="">Change Periode</strong>
          <a-date-picker
            class="ml-2"
            style="width: 200px;"
            v-model="input.periode"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
          <a-button class="btn btn-output-primary ml-2" @click="changePeriode">Simpan</a-button>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: mapState(['settings', 'user']),
  created () {
    this.input.periode =
      moment(this.$store.state.user.periode, 'YYYY-MM-DD') || moment()
  },
  data() {
    return {
      input: {
        periode: '',
      },
    }
  },
  methods: {
    async changePeriode() {
      // console.log('this.input.periode', this.input.periode)
      var nperiode = this.input.periode.format('YYYY-MM-DD')
      // await this.getSettings()
      var fd = { tgl: nperiode }
      // console.log('fd', fd)
      // fd.periode = nperiode
      // console.log('periode', nperiode)
      // console.log('fd', fd)
      // var res =
      var res = await lou.customUrlPost('auth/change_periode', fd, true, true)
      if (res) {
        this.input.periode = moment(nperiode, 'YYYY-MM-DD')
        this.$store.commit('user/SET_STATE', {
          periode: nperiode,
        })
        localStorage.setItem(
          'userData',
          JSON.stringify(this.$store.state.user),
        )
        // lou.shownotif('Success', '"Periode" has been successfully changed!')
        this.editAble = false
      }
    },
  },
}
</script>
